<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="info">
                <div class="title">{{ $t('comtitle') }}</div>
                <div class="list" v-html="list.configValue"></div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   name: "Profile",
   components: {
    Footer,
    Header
  },
   data () {
    return {
       list: []
    }
  },
  mounted () {
    this.getList()
  },
   methods: {
    getList () {
      this.$common.fetchList('/index/company/profile', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
   }
}
</script>


<style lang="less" scoped>
@import '~@/assets/css/profile';
</style>

